import request from '@/utils/request'

// 获取所有渠道类型
export function getAllChannelType (data) {
  return request({
    url: '/activity/getAllChannelType',
    data
  })
}

// 检查自定义码
export function validateCustomCode (data) {
  return request({
    url: '/admin/redeem-code/validate-custom-code',
    data,
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}

// 新建兑换活动
export function createRedeemActivity (data) {
  return request({
    url: '/activity/createRedeemActivity',
    data
  })
}

// 新建兑换活动
export function getRedeemActivityList (data) {
  return request({
    url: '/activity/getRedeemActivityList',
    data
  })
}

// 新建兑换活动
export function updateRedeemActivityStatus (data) {
  return request({
    url: '/activity/updateRedeemActivityStatus',
    data
  })
}

// 兑换码列表
export function getRedeemCodeList (data) {
  return request({
    url: '/activity/getRedeemCodeList',
    data
  })
}

// 删除兑换码
export function deleteRedeemCode (data) {
  return request({
    url: '/activity/deleteRedeemCode',
    data
  })
}
// 追加兑换码
export function batchCreateRedeemCode (data) {
  return request({
    url: '/activity/batchCreateRedeemCode',
    data
  })
}
// 删除兑换码活动
export function deleteRedeemActivity (data) {
  return request({
    url: '/activity/deleteRedeemActivity',
    data
  })
}
// 更新兑换码活动
export function updateRedeemActivity (data) {
  return request({
    url: '/activity/updateRedeemActivity',
    data
  })
}
// 获取单个活动详情
export function getRedeemActivityDetail (data) {
  return request({
    url: '/activity/getRedeemActivityDetail',
    data
  })
}
// 订阅列表
export function subscriptionProductList (data) {
  return request({
    url: '/pay/subscriptionProductList',
    data
  })
}

// 邀请活动列表
export function getInviteActivityList (data) {
  return request({
    url: '/activity/getInviteActivityList',
    data
  })
}

// 邀请活动列表
export function deleteInviteActivity (data) {
  return request({
    url: '/activity/deleteInviteActivity',
    data
  })
}

// 邀请活动列表
export function getInviteActivityStatistics (data) {
  return request({
    url: '/activity/getInviteActivityStatistics',
    data
  })
}

// 邀请活动列表
export function createInviteActivity (data) {
  return request({
    url: '/activity/createInviteActivity',
    data
  })
}

// 获取单个邀请活动详情
export function getInviteActivityDetail (data) {
  return request({
    url: '/activity/getInviteActivityDetail',
    data
  })
}

// 获取单个邀请活动详情
export function updateInviteActivity (data) {
  return request({
    url: '/activity/updateInviteActivity',
    data
  })
}

// 邀请记录列表
export function getInviteRecordList (data) {
  return request({
    url: '/activity/getInviteRecordList',
    data
  })
}

// 更新邀请活动状态
export function updateInviteActivityStatus (data) {
  return request({
    url: '/activity/updateInviteActivityStatus',
    data
  })
}

// 获取金币
export function goldList (data) {
  return request({
    url: '/v1/pay/goldList',
    data
  })
}

// 获取金币
export function couponCodeGetGoods (data) {
  return request({
    url: '/pay/couponCodeGetGoods',
    data
  })
}

// 获取金币
export function downloadInviteCode (data) {
  return request({
    url: '/activity/downloadInviteCode',
    data
  })
}

// 获取金币
export function GetZoneGoods (data) {
  return request({
    url: '/pay/payType/zoneGoodsList',
    data
  })
}

// 邀请活动列表(外部Kol个人数据)
export function getInviteActivityListFromExternalKol (data) {
  return request({
    url: '/activity/getInviteActivityListFromExternalKol',
    data
  })
}

// 邀请活动列表统计数据(外部Kol个人数据)
export function getInviteActivityStatisticsFromExternalKol (data) {
  return request({
    url: '/activity/getInviteActivityStatisticsFromExternalKol',
    data
  })
}

// 邀请记录列表(外部Kol个人数据)
export function getInviteRecordListFromExternalKol (data) {
  return request({
    url: '/activity/getInviteRecordListFromExternalKol',
    data
  })
}
